import { Container, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const SMainContent = styled(Col)`
    border-radius: 10px;
    a,
    a:hover {
        text-decoration: none;
    }
`;





export const SPageSidebar = styled(Col)``;
